/* Base Styles */
@font-face {
  font-family: 'Einhorn Std Regular';
  src: url('../fonts/Einhorn%20Std%20Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.home-content {
  position: relative; /* This makes the container a reference for absolutely positioned children */
  height: auto;
  overflow: hidden;
  padding: 0;
}

.text-content {
  background-color: transparent;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  z-index: 2; /* Make sure text stays above the image */
}

.image-container {
  position: absolute; /* Allows the image to sit behind the text */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Image behind the text */
}

.image-container2 {
  position: absolute; /* Allows the image to sit behind the text */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Image behind the text */
}

.faded-image {
  background: url('../assets/matchlessg3wo.jpeg') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(5px); /* Apply a slight blur */
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%); /* Extend gradient fade on the left for large screens */
  -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
}

.faded-RepairShopImage {
  background: url('../assets/repairShop.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(5px); /* Apply a slight blur */
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%); /* Extend gradient fade */
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
}

.faded-RallyImage {
  background: url('../assets/rally.jpeg') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(5px); /* Apply a slight blur */
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%); /* Extend gradient fade */
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
}
.faded-factoryImage {
  background: url('../assets/factory.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  filter: blur(5px); /* Apply a slight blur */
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 90%); /* Extend gradient fade on the left for large screens */
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 90%);
}

/* Header grid styles */
.header-grid {
  display: grid;
  grid-template-columns: 1fr; /* Single column for small screens */
  grid-template-rows: auto auto auto;
  gap: 10px;
  justify-items: center;
  align-items: center;
  margin-top: 0; /* Reset margin for small screens */
  width: 100%; /* Full width on small screens */
  padding: 20px;
  top: 50px;
  
  text-align: center; /* Center text for mobile */
}

/* Typography for headers */
.small-header {
  font-family: 'Einhorn Std Regular', 'Arial Black', sans-serif;
  font-size: 2em; /* Standardize font size */
  margin: 0;
  margin-top: 100px;
  margin-bottom: -60px;
  margin-left: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
}

.jampot-title {
  font-family: 'Einhorn Std Regular', 'Arial Black', sans-serif;
  font-size: 8em; /* Larger font size for the main title */
  margin: 0;
  letter-spacing: 2px;
  margin-left: 40px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
}

/* Adjust West of England header */
.small-header.west-of-england {
  margin-left: 280px;
  margin-top: -100px; /* Adjust spacing for consistency */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
}

/* Masonry Layout for Photos */
.masonry-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* 2 columns for small screens */
  gap: 10px;
  margin: 20px 0;
}

/* Responsive Scaling and positioning */
@media (min-width: 768px) {
  .home-content {
    height: 100vh; /* Full screen height */
    padding: 0;
  }

  .text-content {
    width: 50%; /* Limit the text width */
    text-align: left; /* Align text left */
    position: relative;
    margin-left: 10%; /* Center the text block within the screen */
    z-index: 2; /* Ensure it is above the image */
  }

  .image-container {
    width: 100%; /* Image takes the full width of the container */
    height: 100%; /* Full height of the container */
    top: 0;
    left: 0;
    z-index: -1; /* Ensure it is above the image */
  }

 
  .faded-image {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%); /* Longer fade for large screens */
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
  }

  /* Header grid for larger screens */
  .header-grid {
    width: 600px;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for larger screens */
    margin-top: 100px;
    gap: 20px;
  }

  .small-header {
    width: 500px;
    font-size: 2em; /* Scale up headers */
    margin-left: 40px;
    margin-top: -180px; /* Adjust spacing for consistency */
  }

  .jampot-title {
    margin-left: -560px;
    margin-top: 10px;
    font-size: 10em; /* Main title stays large */
  }

  .small-header.west-of-england {
    font-size: 2em; /* Scale up West of England header */
    margin-left: -410px;
    margin-top: 110px; /* Adjust spacing for consistency */
  }
}

@media (max-width: 900px) {
  .header-grid {
    transform: scale(0.9); /* Scale down for small screens */
    margin-left: 0px;
    width: 100%; /* Ensure it takes up full width */
    text-align: center; /* Center text inside the grid */
    margin-left: -60px;
    margin-top: -686px;
  }


  
  .home-content {
    display: flex;
    flex-direction: column-reverse; /* Stack image above text */
    height: auto;
  }

  .image-container {
    position: relative; /* Make the image flow within the layout */
    width: 100%;
    height: auto;
    z-index: 1; /* Keep it within the layout */
  }

  .faded-image {
    padding-top: 110%;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
  }

  .text-content {
    position: relative;
    width: 100%;
    z-index: 2;
    color: black; /* Ensure readability */
  }
}

@media (max-width: 600px) {
  .header-grid {
    transform: scale(0.9); /* Further scaling for smaller screens */
    width: 100vw;
    margin-top: -470px;
    padding: 0;
  }

  .home-content {
    padding: 0px;
  }

  .image-container {
    width: 100%;
    margin-top: 0;
    height: auto;
  }

  .faded-image {
    margin-top: -40px;
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 250%);
  }

  .jampot-title {
    font-size: 8.5em; /* Smaller size for very small screens */
  }

  .small-header.west-of-england,
  .small-header {
    font-size: 1.6em; /* Smaller size for very small screens */
  }
}